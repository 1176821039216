import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Infrallex
			</title>
			<meta name={"description"} content={"Вивільни свого внутрішнього бійця - тренуйся з Infrallex для сили, точності та продуктивності"} />
			<meta property={"og:title"} content={"Головна | Infrallex"} />
			<meta property={"og:description"} content={"Вивільни свого внутрішнього бійця - тренуйся з Infrallex для сили, точності та продуктивності"} />
			<meta property={"og:image"} content={"https://infrallex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://infrallex.com/img/1295757993.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://infrallex.com/img/1295757993.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://infrallex.com/img/1295757993.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://infrallex.com/img/1295757993.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://infrallex.com/img/1295757993.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://infrallex.com/img/1295757993.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-16">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 0px"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Персональний тренер з боксу
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Ласкаво просимо до Infrallex, вашого спеціального шляху до оволодіння мистецтвом боксу. Наші послуги персональних тренувань розроблені з урахуванням ваших індивідуальних цілей, незалежно від того, чи ви вперше виходите на ринг, чи хочете вдосконалити свої бойові навички.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							sm-width="100%"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Звʼязатися
						</Button>
					</Box>
				</Box>
				<Image
					src="https://infrallex.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://infrallex.com/img/2.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Персоналізовані програми тренувань
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Спеціалізовані програми з боксу, кікбоксингу та змішаних бойових мистецтв (ММА), розроблені відповідно до вашого рівня фізичної підготовки та цілей.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://infrallex.com/img/3.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Універсальний фітнес
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Окрім боксу, ми пропонуємо тренування з фітнесу, кардіо, TRX, кросфіту та бодібілдингу, забезпечуючи всебічний підхід до вашого фізичного розвитку.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://infrallex.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Експертне керівництво
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Навчайтеся у тренера з великим досвідом у різних формах боксу та фітнесу, який допоможе вам досягти найкращих результатів.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			Унікальні переваги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Увага один на один
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Індивідуальний фокус на вашій техніці, стратегії та результативності, що гарантує вам максимальну віддачу від кожного заняття.
					</Text>
				</Box>
				<Image src="https://infrallex.com/img/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://infrallex.com/img/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Цілісний підхід
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Акцент на психологічній стійкості, стратегічному мисленні та фізичній підготовці, щоб підготувати вас до всіх аспектів боксу.
					</Text>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#3f24d8"
						hover-color="--darkL1"
						hover-background="rgba(63, 36, 216, 0)"
						sm-width="100%"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});